import customSingleSpa from "../customSingleSpa";
import React from "react";
function useNumberFormat(price, forex) {
    return price == "Quote"
        ? price
        : "$" +
            Intl.NumberFormat("es-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(parseFloat(price)) +
            " " +
            forex;
}
var reactLifecycles = customSingleSpa({
    React: React,
    rootComponent: useNumberFormat,
});
export var bootstrap = reactLifecycles.bootstrap;
export var mount = reactLifecycles.mount;
export var unmount = reactLifecycles.unmount;
export default useNumberFormat;
