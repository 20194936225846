var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, memo } from "react";
import { components } from "react-select";
import Icon from "../Icon";
import { ContSelect, SelectInput, ContOptionText, OptionText, DivIcon, TextMessage, ContSingleValue, SingleValueText, ContIconOption, Label, TextSpan, } from "./styles";
import colors from "../../../theme/colors";
import customSingleSpa from "../../../customSingleSpa";
var Select = function (_a) {
    var disabled = _a.disabled, name = _a.name, optionSelect = _a.optionSelect, widthSelect = _a.widthSelect, widthSelectMedia = _a.widthSelectMedia, marginSelect = _a.marginSelect, label = _a.label, value = _a.value, multiValue = _a.multiValue, hasErrors = _a.hasErrors, hasWarning = _a.hasWarning, message = _a.message, placeholder = _a.placeholder, className = _a.className, onBlurSelect = _a.onBlurSelect, searchable = _a.searchable, onChangeSelect = _a.onChangeSelect, defaultValue = _a.defaultValue, requiredField = _a.requiredField, width = _a.width, classNameLabel = _a.classNameLabel, multi = _a.multi, height = _a.height, radius = _a.radius, color = _a.color;
    var _b = useState(""), valueSelected = _b[0], setValueSelected = _b[1];
    var DropdownIndicator = function (propsIndicator) {
        useEffect(function () {
            var _a, _b;
            ((_a = propsIndicator.getValue()[0]) === null || _a === void 0 ? void 0 : _a.value) &&
                setValueSelected((_b = propsIndicator.getValue()[0]) === null || _b === void 0 ? void 0 : _b.value);
        }, [propsIndicator.getValue()]);
        return (_jsx(components.DropdownIndicator, __assign({}, propsIndicator, { children: (hasErrors || hasWarning) && _jsx(DivIcon, {}) })));
    };
    var Option = function (propsOption) {
        return (_jsx(components.Option, __assign({}, propsOption, { children: _jsxs(ContOptionText, { children: [_jsx(OptionText, { children: propsOption.label }), (propsOption === null || propsOption === void 0 ? void 0 : propsOption.value) === valueSelected && (_jsx(ContIconOption, {}))] }) })));
    };
    var SingleValue = function (propsSingleValue) {
        return (_jsx(components.SingleValue, __assign({}, propsSingleValue, { children: _jsx(ContSingleValue, { children: _jsx(SingleValueText, __assign({ color: disabled ? colors.GrayText50 : color }, { children: propsSingleValue.children })) }) })));
    };
    return (_jsxs(ContSelect, __assign({ labelActive: !!label, className: className, width: widthSelect, widthMedia: widthSelectMedia, margin: marginSelect }, { children: [label && (_jsxs(Label, __assign({ className: requiredField
                    ? "label-spec " + classNameLabel
                    : "label " + classNameLabel }, { children: [label, requiredField && _jsx(TextSpan, { children: "*" })] }))), _jsx(SelectInput, __assign({ name: name, width: width, height: height, options: optionSelect, placeholder: placeholder, radius: radius }, (value && value === " "
                ? { value: null }
                : {
                    value: optionSelect &&
                        (optionSelect === null || optionSelect === void 0 ? void 0 : optionSelect.find(function (option) { return (option === null || option === void 0 ? void 0 : option.value) === value; })),
                }), (multiValue && {
                value: multiValue === null || multiValue === void 0 ? void 0 : multiValue.filter(function (option) {
                    return optionSelect === null || optionSelect === void 0 ? void 0 : optionSelect.find(function (option_default) { return (option_default === null || option_default === void 0 ? void 0 : option_default.value) === (option === null || option === void 0 ? void 0 : option.value); });
                }),
            }), (defaultValue && (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.length) == 0
                ? { defaultValue: null }
                : {
                    defaultValue: defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.filter(function (option) {
                        return optionSelect === null || optionSelect === void 0 ? void 0 : optionSelect.find(function (option_default) { return (option_default === null || option_default === void 0 ? void 0 : option_default.value) === option.value; });
                    }),
                }), { components: {
                    DropdownIndicator: DropdownIndicator,
                    Option: Option,
                    SingleValue: SingleValue,
                    IndicatorSeparator: null,
                }, onChange: function (newValue) {
                    onChangeSelect &&
                        onChangeSelect(newValue);
                }, onBlur: onBlurSelect, hasErrors: hasErrors, isDisabled: disabled, isMulti: multi, isSearchable: searchable })), message && (_jsxs(TextMessage, __assign({ color: hasErrors ? colors.errorForm : colors.GrayText }, { children: [hasErrors && (_jsx(Icon, { icon: "exclamation-circle", size: "18px", color: "#D21C1C", className: "mr-1" })), message] })))] })));
};
var reactLifecycles = customSingleSpa({
    React: React,
    rootComponent: Select,
});
export var bootstrap = reactLifecycles.bootstrap;
export var mount = reactLifecycles.mount;
export var unmount = reactLifecycles.unmount;
export default memo(Select);
