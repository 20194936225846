export default {
    primary: "#231D50",
    primary10: "rgba(35,29,80,0.9)",
    primary20: "rgba(35,29,80,0.8)",
    primary30: "rgba(35,29,80,0.7)",
    primary40: "rgba(35,29,80,0.6)",
    primary50: "rgba(35,29,80,0.5)",
    secondary: "#1188E7",
    secondary10: "#1188E7",
    secondary20: "#1188E7",
    secondary30: "#1188E7",
    secondary40: "#1188E7",
    secondary50: "#1188E7",
    black: "#000000",
    black10: "#000000",
    black20: "#222222",
    black30: "#000000",
    black40: "#000000",
    black50: "rgba(34,34,34,0.5)",
    black80: "rgba(34,34,34,0.2)",
    NeutralBackgroundWhite: "#FFFFFF",
    GrayText: "#242424",
    errorForm: "#f44336",
    GrayText80: "#242424",
    GrayText50: "#242424",
    GrayText100: "#242424",
    GrayText20: "#242424",
    GrayText30: "#242424",
    GrayText40: "#242424",
    GrayText10: "#242424",
    Base50: "#242424",
    shadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
};
