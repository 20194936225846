import customSingleSpa from "../customSingleSpa";
import React from "react";
import { toast } from "react-toastify";
var showToast = function (message, type, options) {
    if (options === void 0) { options = {}; }
    switch (type) {
        case "info":
            return toast.info(message, options);
        case "error":
            return toast.error(message, options);
        case "success":
            return toast.success(message, options);
        case "warning":
            return toast.warning(message, options);
        default:
            return null;
    }
};
var reactLifecycles = customSingleSpa({
    React: React,
    rootComponent: showToast,
});
export var bootstrap = reactLifecycles.bootstrap;
export var mount = reactLifecycles.mount;
export var unmount = reactLifecycles.unmount;
export default showToast;
