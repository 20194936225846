var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, memo, useState } from "react";
import { Container, DivInput, Input, Label, TextMessage, DivIcon, TextSpan, } from "./styled";
import colors from "../../../theme/colors";
import Icon from "../Icon";
import customSingleSpa from "../../../customSingleSpa";
var InputText = forwardRef(function (_a, ref) {
    var label = _a.label, labelFontSize = _a.labelFontSize, margin = _a.margin, inputWidth = _a.inputWidth, inputHeight = _a.inputHeight, inputFontSize = _a.inputFontSize, inputTextColor = _a.inputTextColor, bottom = _a.bottom, top = _a.top, type = _a.type, defaultValue = _a.defaultValue, disabled = _a.disabled, placeholder = _a.placeholder, name = _a.name, readOnly = _a.readOnly, hasErrors = _a.hasErrors, message = _a.message, onClick = _a.onClick, onChange = _a.onChange, messageColor = _a.messageColor, value = _a.value, onInput = _a.onInput, spinButtonsDisabled = _a.spinButtonsDisabled, showPassword = _a.showPassword, onBlurText = _a.onBlurText, onPaste = _a.onPaste, requiredField = _a.requiredField;
    var _b = useState(false), activeTypePassword = _b[0], setActiveTypePassword = _b[1];
    var typePassword = function () {
        setActiveTypePassword(function (prevState) { return !prevState; });
    };
    var preventCopyPaste = function (e) {
        e.preventDefault();
        return false;
    };
    return (_jsxs(Container, __assign({ bottom: bottom, top: top, inputWidth: inputWidth, margin: margin, disabled: disabled }, { children: [label && (_jsxs(Label, __assign({ labelFontSize: labelFontSize, className: requiredField ? "label-spec" : "label", labelColor: hasErrors && colors.GrayText, htmlFor: label.toString() }, { children: [label, " ", requiredField && _jsx(TextSpan, { children: "*" })] }))), _jsxs(DivInput, __assign({ inputWidth: inputWidth, inputHeight: inputHeight }, { children: [_jsx(Input, { onPaste: onPaste ? function () { } : function (e) { return preventCopyPaste(e); }, hasErrors: hasErrors, inputHeight: inputHeight, type: activeTypePassword ? "text" : type, placeholder: placeholder, name: name, inputFontSize: inputFontSize, inputTextColor: inputTextColor, defaultValue: defaultValue, onClick: onClick, onChange: onChange, onBlur: onBlurText, disabled: disabled, readOnly: readOnly, messageColor: messageColor, value: value, onInput: onInput, autoComplete: "none", spinButtonsDisabled: spinButtonsDisabled, ref: ref, id: label.toString() }), _jsxs(DivIcon, { children: [hasErrors && (_jsx(Icon, { icon: "information-circle", size: 24, color: colors.errorForm })), showPassword && (_jsx(Icon, { icon: activeTypePassword ? "eye" : "eye-off1", size: 24, color: colors.GrayText80, onClick: typePassword }))] })] })), message && (_jsx(TextMessage, __assign({ inputHeight: inputHeight, color: hasErrors ? colors.errorForm : messageColor }, { children: message })))] })));
});
var reactLifecycles = customSingleSpa({
    React: React,
    rootComponent: InputText,
});
export var bootstrap = reactLifecycles.bootstrap;
export var mount = reactLifecycles.mount;
export var unmount = reactLifecycles.unmount;
export default memo(InputText);
