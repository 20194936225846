var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
import { ifProp, prop, switchProp } from "styled-tools";
import colors from "../../theme/colors";
import media from "../../theme/media";
export var ContentButton = styled.a(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: ", " !important;\n  align-items: center;\n  gap: 5px;\n  width: 100%;\n  height: 100%;\n  border-radius: ", ";\n"], ["\n  display: flex;\n  justify-content: ", " !important;\n  align-items: center;\n  gap: 5px;\n  width: 100%;\n  height: 100%;\n  border-radius: ", ";\n"])), prop("textAlign", "center"), function (props) { return (props.radius ? props.radius : "40px"); });
export var ContainerButton = styled.button(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  font-size: 16px;\n  //font-weight: 700;\n  min-width: calc(", " + 16px);\n  margin: ", ";\n  max-width: calc(", " + 16px);\n  height: calc(", " + 16px);\n  border-radius: ", ";\n  border: 8px solid transparent;\n\n  ", ";\n\n  ", "\n  * {\n    pointer-events: none;\n  }\n  svg {\n    margin-left: 8px;\n  }\n  align-items: center;\n\n  ", ";\n\n  ", "\n  ", "\n"], ["\n  display: flex;\n  width: 100%;\n  font-size: 16px;\n  //font-weight: 700;\n  min-width: calc(", " + 16px);\n  margin: ", ";\n  max-width: calc(", " + 16px);\n  height: calc(", " + 16px);\n  border-radius: ", ";\n  border: 8px solid transparent;\n\n  ", ";\n\n  ", "\n  * {\n    pointer-events: none;\n  }\n  svg {\n    margin-left: 8px;\n  }\n  align-items: center;\n\n  ", ";\n\n  ", "\n  ", "\n"])), function (props) { return props.minWidth; }, prop("margin"), function (props) { return (props.width ? props.width : "120px"); }, function (props) { return (props.height ? props.height : "40px"); }, function (props) { return (props.radius ? props.radius : "40px"); }, function (props) {
    return props.widthMob && media.lessThan("lg")(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\t\tmax-width: 100%;\n\t\t\twidth: 100%;"], ["\n\t\t\tmax-width: 100%;\n\t\t\twidth: 100%;"])));
}, ifProp("noBorder", "border-left: none; border-right: none;"), ifProp({ typeButton: "link" }, "justify-content: left;", "justify-content: center;"), switchProp("typeButton", {
    primary: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      a {\n        background: ", ";\n        color: white;\n      }\n      :hover {\n        a {\n          background: ", ";\n        }\n      }\n      :active {\n        a {\n          background: ", ";\n        }\n      }\n      /* :focus {\n\t\t\t\tborder-color: ", ";\n\t\t\t} */\n      :disabled {\n        background: initial;\n        a {\n          background: ", ";\n        }\n      }\n    "], ["\n      a {\n        background: ", ";\n        color: white;\n      }\n      :hover {\n        a {\n          background: ", ";\n        }\n      }\n      :active {\n        a {\n          background: ", ";\n        }\n      }\n      /* :focus {\n\t\t\t\tborder-color: ", ";\n\t\t\t} */\n      :disabled {\n        background: initial;\n        a {\n          background: ", ";\n        }\n      }\n    "])), colors.primary, colors.primary40, colors.primary, colors.primary10, colors.primary30),
    secondary: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      a {\n        border: 2px solid ", ";\n        color: ", ";\n        p {\n          color: ", ";\n        }\n      }\n      :hover {\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :active {\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :focus {\n        border-color: ", ";\n      }\n      :disabled {\n        background: initial;\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n    "], ["\n      a {\n        border: 2px solid ", ";\n        color: ", ";\n        p {\n          color: ", ";\n        }\n      }\n      :hover {\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :active {\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :focus {\n        border-color: ", ";\n      }\n      :disabled {\n        background: initial;\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n    "])), colors.primary, colors.primary, colors.primary, colors.primary40, colors.primary40, colors.primary, colors.primary, colors.primary10, colors.primary30, colors.primary30),
    subtle: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      a {\n        border: 2px solid ", ";\n        p {\n          color: ", ";\n        }\n      }\n      :hover {\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :active {\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :focus {\n        border-color: ", ";\n      }\n      :disabled {\n        background: initial;\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n    "], ["\n      a {\n        border: 2px solid ", ";\n        p {\n          color: ", ";\n        }\n      }\n      :hover {\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :active {\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :focus {\n        border-color: ", ";\n      }\n      :disabled {\n        background: initial;\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n    "])), colors.black10, colors.primary, colors.black10, colors.primary40, colors.black10, colors.primary, colors.primary10, colors.black10, colors.primary30),
    reject: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      a {\n        border: 2px solid ", ";\n        p {\n          color: ", ";\n        }\n      }\n      :hover {\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :active {\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :focus {\n        border-color: ", ";\n      }\n      :disabled {\n        background: initial;\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n    "], ["\n      a {\n        border: 2px solid ", ";\n        p {\n          color: ", ";\n        }\n      }\n      :hover {\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :active {\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :focus {\n        border-color: ", ";\n      }\n      :disabled {\n        background: initial;\n        a {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n    "])), colors.black, colors.black, colors.black50, colors.black50, colors.black, colors.black, colors.black50, colors.black10, colors.black10),
    text: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      :hover {\n        p {\n          color: ", ";\n        }\n      }\n      :active {\n        p {\n          color: ", ";\n        }\n      }\n      :focus {\n        a {\n          // background: ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :disabled {\n        background: initial;\n        p {\n          color: ", ";\n        }\n      }\n      p {\n        color: ", ";\n        ", "\n      }\n    "], ["\n      :hover {\n        p {\n          color: ", ";\n        }\n      }\n      :active {\n        p {\n          color: ", ";\n        }\n      }\n      :focus {\n        a {\n          // background: ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :disabled {\n        background: initial;\n        p {\n          color: ", ";\n        }\n      }\n      p {\n        color: ", ";\n        ", "\n      }\n    "])), colors.primary40, colors.primary40, colors.primary10, colors.primary40, colors.primary30, function (_a) {
        var textColor = _a.textColor;
        return textColor || colors.primary;
    }, function (_a) {
        var textDecoration = _a.textDecoration;
        return textDecoration ? "text-decoration: ".concat(textDecoration, ";") : "";
    }),
    neutral: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      a {\n        background: ", ";\n        p {\n          color: ", ";\n        }\n      }\n      :hover {\n        p {\n          color: ", ";\n        }\n      }\n      :active {\n        p {\n          color: ", ";\n        }\n      }\n      :focus {\n        a {\n          p {\n            color: ", ";\n          }\n        }\n      }\n    "], ["\n      a {\n        background: ", ";\n        p {\n          color: ", ";\n        }\n      }\n      :hover {\n        p {\n          color: ", ";\n        }\n      }\n      :active {\n        p {\n          color: ", ";\n        }\n      }\n      :focus {\n        a {\n          p {\n            color: ", ";\n          }\n        }\n      }\n    "])), colors.NeutralBackgroundWhite, colors.primary, colors.primary40, colors.primary40, colors.primary40),
}), ifProp("disabled", css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      background: ", ";\n      > p {\n        color: ", ";\n      }\n      > svg > path {\n        color: ", " !important;\n      }\n    "], ["\n      background: ", ";\n      > p {\n        color: ", ";\n      }\n      > svg > path {\n        color: ", " !important;\n      }\n    "])), colors.primary30, colors.NeutralBackgroundWhite, colors.NeutralBackgroundWhite)));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
