export default {
    "Primary/Red": "#EA0029",
    "Primary/Black": "#000000",
    "Light/Red": "#FFE7E7",
    "Default/Blue": "#334D6E",
    gray: "#C2CFE0",
    grayBtnIcon: "#C4C4C4",
    darkgray: "#90A0B7",
    "Default/Black": "#000000",
    black: "#192A3E",
    "text/dark": "#2E3B4C",
    bg: "#F5F6F8",
    green: "#3DC627",
    white: "#FFFFFF",
    "Ink/Normal": "#252A31",
    // Primary
    MagentaWise: "#ED056F",
    MagentaWise50: "#FA2486",
    MagentaWise40: "#FB509E",
    MagentaWise30: "#FDA8CF",
    MagentaWise20: "#FED3E7",
    MagentaWise10: "#FEDCEC",
    BlueWise: "#39C2F3",
    BlueWise50: "#5ACCF5",
    BlueWise40: "#9CE0F9",
    BlueWise30: "#BDEBFB",
    BlueWise20: "#DEF5FD",
    BlueWise10: "#E5F7FD",
    PurpleWise: "#38046C",
    PurpleWise50: "#6A08CE",
    PurpleWise40: "#8211F6",
    PurpleWise30: "#A24CF8",
    PurpleWise20: "#CA99FB",
    PurpleWise10: "#F2E5FE",
    // Line
    divider: "#EBEFF2",
    // background
    NeutralBackground: "linear-gradient(230.86deg, #FFEAF3 0%, #F9F3FF 42.89%, #DAF5FF 99.21%);",
    Base50: "#F6F6F8",
    // title and text
    GrayText: "#4B4B4B",
    GrayTextOpacity: "rgba(75,75,75,0.5)",
    GrayText80: "#626262",
    GrayText50: "#A5A5A5",
    GrayText20: "#BCBCBC",
    GrayText10: "#E8E8E8",
    GrayScaleLine: "#D9DBE9",
    Title: "#FFFDFB",
    // Neutral
    // For backgrounds, borders, texts and actions.
    NeutralBackgroundWhite: "#FFFFFF",
    NeutralBackground10: "#F6F8FC",
    NeutralBackground20: "#F1F4F9",
    NeutralBackground30: "#E2E8F0",
    NeutralBackground40: "#CBD4E1",
    NeutralBackground50: "#94A3B8",
    NeutralBackground100: "#0F1A2A",
    FooterBackground: "#33313B",
    RowBackground: "#F4F4F4",
    // Semantic Colors
    // Success
    success: "#002611",
    success10: "#E8FCF1",
    success20: "#A5E1BF",
    success40: "#419E6A",
    success40Opacity: "rgba(65,158,106,0.1)",
    success60: "#00632B",
    success80: "#00401C",
    // info
    info: "#002987",
    info10: "#D3E1FE",
    info20: "#7EA5F8",
    info40: "#4D82F3",
    info40Opacity: "rgba(77,130,243,0.1)",
    info60: "#2563EB",
    info80: "#0037B3",
    // alert
    alert: "#4D2900",
    alert10: "#FFF5D5",
    alert20: "#FFDE81",
    alert40: "#EFB008",
    alert40Opacity: "rgba(239,176,8,0.1)",
    alert60: "#976400",
    alert80: "#724B00",
    // error
    error: "#660000",
    error10: "#FFEBEB",
    error20: "#FC9595",
    error40: "#D83232",
    error40Opacity: "rgba(216,50,50,0.1)",
    error60: "#B01212",
    error80: "#8C0000",
    errorForm: "#DE4D4E",
    // in production
    inproduction: "#E14908",
    inproductionOpacity: "rgba(225, 73, 8, 0.1)",
    inProcess: "#E5713B",
    inProcessOpacity: "#FCF1EB",
    // shades
    small: "0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31)",
    medium: "0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)",
    large: "0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)",
    xLarge: "0px 18px 28px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)",
    sidebarShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
    //Linear
    LinearGradient: "linear-gradient(230.86deg, #FFD8EA 0%, #EFDEFF 42.89%, #BEEEFF 99.21%)",
};
