var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { forwardRef, memo } from "react";
import { Container, DivInput, Input, Label, TextMessage, DivIcon, ListFiles, TextSpan, } from "./styled";
import colors from "../../../theme/colors";
import Icon from "../Icon";
import customSingleSpa from "../../../customSingleSpa";
var InputFile = forwardRef(function (_a, ref) {
    var label = _a.label, accept = _a.accept, labelFontSize = _a.labelFontSize, margin = _a.margin, inputWidth = _a.inputWidth, inputHeight = _a.inputHeight, inputFontSize = _a.inputFontSize, inputTextColor = _a.inputTextColor, bottom = _a.bottom, top = _a.top, defaultValue = _a.defaultValue, disabled = _a.disabled, placeholder = _a.placeholder, name = _a.name, readOnly = _a.readOnly, hasErrors = _a.hasErrors, message = _a.message, onClick = _a.onClick, onChange = _a.onChange, onBlur = _a.onBlur, messageColor = _a.messageColor, onInput = _a.onInput, spinButtonsDisabled = _a.spinButtonsDisabled, autocomplete = _a.autocomplete, onRemove = _a.onRemove, fileName = _a.fileName, listFiles = _a.listFiles, removeName = _a.removeName, requiredField = _a.requiredField, _b = _a.multiple, multiple = _b === void 0 ? false : _b;
    var removeAction = function (name) {
        onRemove(name);
        if (removeName)
            removeName(name);
    };
    return (_jsxs(Container, __assign({ bottom: bottom, top: top, inputWidth: inputWidth, margin: margin, disabled: disabled }, { children: [label && (_jsxs(Label, __assign({ labelFontSize: labelFontSize, labelColor: hasErrors && colors.GrayText, className: requiredField ? "label-spec" : "label", htmlFor: label.toString() }, { children: [label, " ", requiredField && _jsx(TextSpan, { children: "*" })] }))), _jsxs(DivInput, __assign({ inputWidth: inputWidth, inputHeight: (listFiles === null || listFiles === void 0 ? void 0 : listFiles.length)
                    ? listFiles.length > 2
                        ? "".concat(listFiles.length * 26, "px")
                        : inputHeight
                    : inputHeight }, { children: [_jsx(Input, { hasErrors: hasErrors, inputHeight: (listFiles === null || listFiles === void 0 ? void 0 : listFiles.length)
                            ? listFiles.length > 2
                                ? "".concat(listFiles.length * 26, "px")
                                : inputHeight
                            : inputHeight, type: "file", placeholder: placeholder, name: name, inputFontSize: inputFontSize, inputTextColor: inputTextColor, defaultValue: defaultValue, hasFiles: !!listFiles, onClick: onClick, onChange: onChange, onBlur: onBlur, disabled: disabled, readOnly: readOnly, messageColor: messageColor, accept: accept !== null && accept !== void 0 ? accept : ".jpg,.png,.pdf,.jpeg, svg", onInput: onInput, autoComplete: autocomplete, spinButtonsDisabled: spinButtonsDisabled, ref: ref, id: label.toString(), text: (listFiles === null || listFiles === void 0 ? void 0 : listFiles.length)
                            ? ""
                            : fileName
                                ? fileName
                                : placeholder !== null && placeholder !== void 0 ? placeholder : "Select files", multiple: multiple }), (listFiles === null || listFiles === void 0 ? void 0 : listFiles.length) ? (_jsxs(_Fragment, { children: [_jsx(ListFiles, { children: listFiles.map(function (file, idx) { return (_jsxs("div", __assign({ className: "".concat((file === null || file === void 0 ? void 0 : file.length) > 40
                                        ? "w-9/12 text-ellipsis whitespace-nowrap overflow-x-clip"
                                        : "max-w-min") }, { children: [_jsx(Icon, { icon: "x", size: 10, onClick: function () { return removeAction(file); }, className: "mr-1.5" }), _jsx("span", __assign({ className: "mt-0.5" }, { children: file }))] }), idx)); }) }), _jsx(DivIcon, { children: _jsx(Icon, { icon: "upload1", size: 20, color: colors.GrayText80, onClick: onClick }) })] })) : null, !(listFiles === null || listFiles === void 0 ? void 0 : listFiles.length) ? (_jsxs(DivIcon, { children: [hasErrors && (_jsx(Icon, { icon: "information-circle", size: 24, color: colors.errorForm })), _jsx(Icon, { icon: "upload1", size: 20, color: colors.GrayText80, onClick: onClick })] })) : null] })), message && (_jsx(TextMessage, __assign({ inputHeight: inputHeight, color: hasErrors ? colors.errorForm : messageColor }, { children: message })))] })));
});
var reactLifecycles = customSingleSpa({
    React: React,
    rootComponent: InputFile,
});
export var bootstrap = reactLifecycles.bootstrap;
export var mount = reactLifecycles.mount;
export var unmount = reactLifecycles.unmount;
export default memo(InputFile);
