var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
import { ifProp, prop, switchProp } from "styled-tools";
import colors from "../../../theme/colors";
import media from "../../../theme/media";
export var ContentButton = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: ", " !important;\n  align-items: center;\n  gap: 10px;\n  width: 100%;\n  height: 100%;\n  border-radius: ", ";\n"], ["\n  display: flex;\n  justify-content: ", " !important;\n  align-items: center;\n  gap: 10px;\n  width: 100%;\n  height: 100%;\n  border-radius: ", ";\n"])), prop("textAlign", "center"), function (props) { return (props.radius ? props.radius : "8px"); });
export var ContainerButton = styled.button(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  font-size: 16px;\n  //font-weight: 700;\n  min-width: calc(", " + 16px);\n  margin: ", ";\n  max-width: calc(", " + 16px);\n  height: calc(", " + 16px);\n  border-radius: ", ";\n  border: 8px solid transparent;\n  font-weight: bold;\n\n  ", ";\n\n  ", "\n  * {\n    pointer-events: none;\n  }\n  align-items: center;\n\n  ", ";\n\n  ", "\n  ", "\n"], ["\n  display: flex;\n  width: 100%;\n  font-size: 16px;\n  //font-weight: 700;\n  min-width: calc(", " + 16px);\n  margin: ", ";\n  max-width: calc(", " + 16px);\n  height: calc(", " + 16px);\n  border-radius: ", ";\n  border: 8px solid transparent;\n  font-weight: bold;\n\n  ", ";\n\n  ", "\n  * {\n    pointer-events: none;\n  }\n  align-items: center;\n\n  ", ";\n\n  ", "\n  ", "\n"])), function (props) { return props.minWidth; }, prop("margin"), function (props) { return (props.width ? props.width : "120px"); }, function (props) { return (props.height ? props.height : "40px"); }, function (props) { return (props.radius ? props.radius : "8px"); }, function (props) {
    return props.widthMob && media.lessThan("lg")(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\t\tmax-width: 100%;\n\t\t\twidth: 100%;"], ["\n\t\t\tmax-width: 100%;\n\t\t\twidth: 100%;"])));
}, ifProp("noBorder", "border-left: none; border-right: none;"), ifProp({ typeButton: "link" }, "justify-content: left;", "justify-content: center;"), switchProp("typeButton", {
    primary: function (props) { return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      span {\n        background: ", ";\n        color: ", ";\n      }\n      :hover {\n        span {\n          background: ", ";\n        }\n      }\n      :active {\n        span {\n          background: ", ";\n        }\n      }\n      :disabled {\n        background: initial;\n        span {\n          background: ", ";\n        }\n      }\n    "], ["\n      span {\n        background: ", ";\n        color: ", ";\n      }\n      :hover {\n        span {\n          background: ", ";\n        }\n      }\n      :active {\n        span {\n          background: ", ";\n        }\n      }\n      :disabled {\n        background: initial;\n        span {\n          background: ", ";\n        }\n      }\n    "])), props.background ? props.background : colors.primary, props.color ? props.color : colors.NeutralBackgroundWhite, props.backgroundHover ? props.backgroundHover : colors.primary40, props.backgroundHover ? props.backgroundHover : colors.primary40, props.backgroundHover ? props.backgroundHover : colors.primary30); },
    secondary: function (props) { return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      span {\n        border: 2px solid ", ";\n        color: ", ";\n        p {\n          color: ", ";\n        }\n      }\n      :hover {\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :active {\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :focus {\n        border-color: ", ";\n      }\n      :disabled {\n        background: initial;\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n    "], ["\n      span {\n        border: 2px solid ", ";\n        color: ", ";\n        p {\n          color: ", ";\n        }\n      }\n      :hover {\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :active {\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :focus {\n        border-color: ", ";\n      }\n      :disabled {\n        background: initial;\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n    "])), props.color ? props.color : colors.NeutralBackgroundWhite, props.color ? props.color : colors.NeutralBackgroundWhite, props.color ? props.color : colors.NeutralBackgroundWhite, props.color ? props.color : colors.primary40, props.color ? props.color : colors.primary40, props.color ? props.color : colors.NeutralBackgroundWhite, props.color ? props.color : colors.NeutralBackgroundWhite, props.color ? props.color : colors.primary10, colors.primary30, colors.primary30); },
    subtle: function (props) { return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      span {\n        border: 2px solid ", ";\n        p {\n          color: ", ";\n        }\n      }\n      :hover {\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :active {\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :focus {\n        border-color: ", ";\n      }\n      :disabled {\n        background: initial;\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n    "], ["\n      span {\n        border: 2px solid ", ";\n        p {\n          color: ", ";\n        }\n      }\n      :hover {\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :active {\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :focus {\n        border-color: ", ";\n      }\n      :disabled {\n        background: initial;\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n    "])), props.color ? props.color : colors.black10, props.color ? props.color : colors.NeutralBackgroundWhite, props.color ? props.color : colors.black10, props.color ? props.color : colors.primary40, props.color ? props.color : colors.black10, props.color ? props.color : colors.NeutralBackgroundWhite, props.color ? props.color : colors.primary10, props.color ? props.color : colors.black10, props.color ? props.color : colors.primary30); },
    reject: function (props) { return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      span {\n        border: 2px solid ", ";\n        p {\n          color: ", ";\n        }\n      }\n      :hover {\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :active {\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :focus {\n        border-color: ", ";\n      }\n      :disabled {\n        background: initial;\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n    "], ["\n      span {\n        border: 2px solid ", ";\n        p {\n          color: ", ";\n        }\n      }\n      :hover {\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :active {\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :focus {\n        border-color: ", ";\n      }\n      :disabled {\n        background: initial;\n        span {\n          border: 2px solid ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n    "])), props.color ? props.color : colors.black, props.color ? props.color : colors.black, props.color ? props.color : colors.black50, props.color ? props.color : colors.black50, props.color ? props.color : colors.black, props.color ? props.color : colors.black, props.color ? props.color : colors.black50, props.color ? props.color : colors.black10, props.color ? props.color : colors.black10); },
    text: function (props) { return css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      :hover {\n        p {\n          color: ", ";\n        }\n      }\n      :active {\n        p {\n          color: ", ";\n        }\n      }\n      :focus {\n        span {\n          // background: ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :disabled {\n        background: initial;\n        p {\n          color: ", ";\n        }\n      }\n      p {\n        color: ", ";\n        ", "\n      }\n    "], ["\n      :hover {\n        p {\n          color: ", ";\n        }\n      }\n      :active {\n        p {\n          color: ", ";\n        }\n      }\n      :focus {\n        span {\n          // background: ", ";\n          p {\n            color: ", ";\n          }\n        }\n      }\n      :disabled {\n        background: initial;\n        p {\n          color: ", ";\n        }\n      }\n      p {\n        color: ", ";\n        ", "\n      }\n    "])), props.color ? props.color : colors.primary40, props.color ? props.color : colors.primary40, colors.primary10, props.color ? props.color : colors.primary40, props.color ? props.color : colors.primary30, function (_a) {
        var textColor = _a.textColor;
        return textColor || colors.primary;
    }, function (_a) {
        var textDecoration = _a.textDecoration;
        return textDecoration ? "text-decoration: ".concat(textDecoration, ";") : "";
    }); },
    neutral: function (props) { return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      span {\n        background: ", ";\n        p {\n          color: ", ";\n        }\n      }\n      :hover {\n        p {\n          color: ", ";\n        }\n      }\n      :active {\n        p {\n          color: ", ";\n        }\n      }\n      :focus {\n        span {\n          p {\n            color: ", ";\n          }\n        }\n      }\n    "], ["\n      span {\n        background: ", ";\n        p {\n          color: ", ";\n        }\n      }\n      :hover {\n        p {\n          color: ", ";\n        }\n      }\n      :active {\n        p {\n          color: ", ";\n        }\n      }\n      :focus {\n        span {\n          p {\n            color: ", ";\n          }\n        }\n      }\n    "])), props.background ? props.background : colors.NeutralBackgroundWhite, props.color ? props.color : colors.primary, props.color ? props.color : colors.primary40, props.color ? props.color : colors.primary40, props.color ? props.color : colors.primary40); },
}), ifProp("disabled", css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      background: ", ";\n      > p {\n        color: ", ";\n      }\n      > svg > path {\n        color: ", " !important;\n      }\n    "], ["\n      background: ", ";\n      > p {\n        color: ", ";\n      }\n      > svg > path {\n        color: ", " !important;\n      }\n    "])), colors.primary30, colors.NeutralBackgroundWhite, colors.NeutralBackgroundWhite)));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
