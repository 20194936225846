import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjsRelativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import customSingleSpa from "../customSingleSpa";
import React from "react";
function useDateFormat(date, format) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(dayjsRelativeTime);
    dayjs.extend(advancedFormat);
    var tzLocal = dayjs.tz.guess();
    tzLocal = "America/New_York";
    dayjs.tz.setDefault(tzLocal);
    return dayjs.utc(date).format(format !== null && format !== void 0 ? format : "MM-DD-YYYY");
}
var reactLifecycles = customSingleSpa({
    React: React,
    rootComponent: useDateFormat,
});
export var bootstrap = reactLifecycles.bootstrap;
export var mount = reactLifecycles.mount;
export var unmount = reactLifecycles.unmount;
export default useDateFormat;
