import { generateMedia } from "styled-media-query";
export default generateMedia({
    xs: "480px",
    s: "580px",
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xlg: "1150px",
    xl: "1280px",
    xxl: "1386px",
    "2xl": "1480px",
    "4xl": "1600px",
});
