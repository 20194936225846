export default {
    primary: "#F9C61B",
    primary10: "#F9C61B",
    primary20: "#F9C61B",
    primary30: "#F9C61B",
    primary40: "#F9C61B",
    primary50: "#F9C61B",
    secondary: "#1188E7",
    secondary10: "#1188E7",
    secondary20: "#1188E7",
    secondary30: "#1188E7",
    secondary40: "#1188E7",
    secondary50: "#1188E7",
    black: "#000000",
    black10: "#000000",
    black20: "#000000",
    black30: "#000000",
    black40: "#000000",
    black50: "#000000",
    black80: "#000000",
    NeutralBackgroundWhite: "#FFFFFF",
};
