var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, memo } from "react";
import Icon from "../Icon";
import { ContentButton, ContainerButton } from "./styled";
import colors from "../../theme/colors";
var Button = forwardRef(function (_a, ref) {
    var type = _a.type, typeButton = _a.typeButton, fontSize = _a.fontSize, fontSizeWeb = _a.fontSizeWeb, textColor = _a.textColor, textButton = _a.textButton, iconName = _a.iconName, iconColor = _a.iconColor, width = _a.width, widthMob = _a.widthMob, height = _a.height, onClick = _a.onClick, disabled = _a.disabled, className = _a.className, radius = _a.radius, fontWeight = _a.fontWeight, margin = _a.margin, textAlign = _a.textAlign, minWidth = _a.minWidth, textDecoration = _a.textDecoration, noBorder = _a.noBorder, iconRightName = _a.iconRightName, loading = _a.loading;
    return (_jsxs(ContainerButton, __assign({ noBorder: noBorder, textDecoration: textDecoration, textColor: textColor, typeButton: typeButton, onClick: onClick, disabled: disabled, type: type, width: width, widthMob: widthMob, minWidth: minWidth, ref: ref, className: className, radius: radius }, (margin && { margin: margin }), { height: height }, { children: [iconName && (_jsx(Icon, { icon: iconName ? iconName : "", size: 24, color: iconColor ? iconColor : colors.GrayText80 })), textButton && typeButton === "link" ? (_jsxs(ContentButton, __assign({ textAlign: textAlign ? textAlign : "center" }, { children: [loading && (_jsx("div", __assign({ className: "btn_loader" }, { children: _jsx("img", { src: "/assets/frame_color.gif", style: { width: "18px" }, alt: "loading" }) }))), textButton] }))) : (_jsxs(ContentButton, __assign({ textAlign: textAlign ? textAlign : "center" }, { children: [loading && (_jsx("div", __assign({ className: "btn_loader" }, { children: _jsx("img", { src: "/assets/frame_color.gif", style: { width: "18px" }, alt: "loading" }) }))), textButton] }))), iconRightName && (_jsx(Icon, { icon: iconRightName ? iconRightName : "", size: 24, color: iconColor ? iconColor : colors.GrayText80 }))] })));
});
export default memo(Button);
