var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import colors from "../../../theme/colors";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: left;\n  margin-bottom: 16px;\n  font-family: \"Nunito\";\n  font-style: normal;\n  position: relative;\n  label {\n    font-weight: 400;\n    font-size: 14px;\n\n    margin-bottom: 8px;\n    color: ", ";\n  }\n  .error {\n    margin-top: 4px;\n    display: flex;\n    gap: 5px;\n    color: #d21c1c;\n    font-weight: normal;\n    font-size: 14px;\n  }\n  span {\n    position: absolute;\n    right: 14px;\n    top: 36px;\n    color: #242424;\n    font-style: normal;\n    font-weight: 600;\n    .icon-btn {\n      cursor: pointer;\n    }\n  }\n"], ["\n  text-align: left;\n  margin-bottom: 16px;\n  font-family: \"Nunito\";\n  font-style: normal;\n  position: relative;\n  label {\n    font-weight: 400;\n    font-size: 14px;\n\n    margin-bottom: 8px;\n    color: ", ";\n  }\n  .error {\n    margin-top: 4px;\n    display: flex;\n    gap: 5px;\n    color: #d21c1c;\n    font-weight: normal;\n    font-size: 14px;\n  }\n  span {\n    position: absolute;\n    right: 14px;\n    top: 36px;\n    color: #242424;\n    font-style: normal;\n    font-weight: 600;\n    .icon-btn {\n      cursor: pointer;\n    }\n  }\n"])), colors.GrayText);
export var ContainerInput = styled.textarea(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 1px solid\n    ", ";\n  box-sizing: border-box;\n  border-radius: 6px;\n  padding: 12px;\n  width: 100%;\n  background: none;\n  margin-top: 8px;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 150%;\n  background: ", ";\n\n  &::-webkit-input-placeholder {\n    color: #242424;\n  }\n  &:-ms-input-placeholder {\n    color: #242424;\n  }\n  &:focus {\n    border: 1px solid ", ";\n  }\n  &:disabled {\n    background-color: ", ";\n    border: 1px solid ", ";\n    color: ", ";\n  }\n"], ["\n  border: 1px solid\n    ", ";\n  box-sizing: border-box;\n  border-radius: 6px;\n  padding: 12px;\n  width: 100%;\n  background: none;\n  margin-top: 8px;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 150%;\n  background: ", ";\n\n  &::-webkit-input-placeholder {\n    color: #242424;\n  }\n  &:-ms-input-placeholder {\n    color: #242424;\n  }\n  &:focus {\n    border: 1px solid ", ";\n  }\n  &:disabled {\n    background-color: ", ";\n    border: 1px solid ", ";\n    color: ", ";\n  }\n"])), function (props) {
    var _a;
    return props.hasErrors
        ? "#d21c1c"
        : ((_a = props.value) === null || _a === void 0 ? void 0 : _a.length) > 0
            ? "black"
            : "#242424";
}, function (props) {
    return props.isDisabled ? colors.black50 : colors.NeutralBackgroundWhite;
}, colors.black, colors.Base50, colors.GrayText50, colors.GrayText50);
var templateObject_1, templateObject_2;
