import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { ToastContainer } from "react-toastify";
import customSingleSpa from "../../../customSingleSpa";
import { Check, CircleAlert, Info, TriangleAlert } from "lucide-react";
var Toast = function () {
    return (_jsx(ToastContainer, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, newestOnTop: false, closeOnClick: false, rtl: false, pauseOnFocusLoss: true, draggable: true, pauseOnHover: true, theme: "light", icon: function (_a) {
            var type = _a.type, theme = _a.theme;
            switch (type) {
                case "info":
                    return _jsx(Info, { className: "stroke-indigo-400" });
                case "error":
                    return _jsx(CircleAlert, { className: "stroke-red-500" });
                case "success":
                    return _jsx(Check, { className: "stroke-green-500" });
                case "warning":
                    return _jsx(TriangleAlert, { className: "stroke-yellow-500" });
                default:
                    return null;
            }
        } }));
};
var reactLifecycles = customSingleSpa({
    React: React,
    rootComponent: Toast,
});
export var bootstrap = reactLifecycles.bootstrap;
export var mount = reactLifecycles.mount;
export var unmount = reactLifecycles.unmount;
export default Toast;
