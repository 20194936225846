import { jsx as _jsx } from "react/jsx-runtime";
import IcomoonReact from "icomoon-react";
import iconSet from "./selection.json";
import * as React from "react";
import customSingleSpa from "../../../customSingleSpa";
var Icon = function (props) {
    return (_jsx(IcomoonReact, { "start-id": props.startId, className: props.className, iconSet: iconSet, color: props.color, size: props.size, icon: props.icon, onClick: props.onClick }));
};
var reactLifecycles = customSingleSpa({
    React: React,
    rootComponent: Icon,
});
export var bootstrap = reactLifecycles.bootstrap;
export var mount = reactLifecycles.mount;
export var unmount = reactLifecycles.unmount;
export default Icon;
